<template>
    <div class="home container-fluid">
    </div>
</template>

<script>
export default {
  name: "Steps"
}
</script>

<style>

</style>